var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title: _vm.appName + " - Service Updated: " + _vm.service.name
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: {
                    name: "services-show",
                    params: { service: _vm.service.id }
                  }
                }
              },
              [_vm._v("Back to " + _vm._s(_vm.service.type))]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Update request submitted")
                        ]),
                        _c("gov-body", [
                          _vm._v(
                            "\n            Your update request for this " +
                              _vm._s(_vm.service.type) +
                              " has been received.\n            It will need to be approved by an admin before the changes will be\n            applied.\n          "
                          )
                        ]),
                        _c(
                          "gov-button",
                          {
                            attrs: {
                              to: {
                                name: "services-show",
                                params: { service: this.$route.params.service }
                              }
                            }
                          },
                          [_vm._v("Back to " + _vm._s(_vm.service.type))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }